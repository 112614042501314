import request from '@/utils/request'
// 获取文章列表
// console.log(store.state.APIinfo)
import store from '@/store'
store.dispatch('getAPI');

var info = JSON.parse(sessionStorage.getItem('APIinfo'));

//获取商品列表
export function productListAPI(data) {
    return request({
      url: `/Product/findListProduct?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}
//按照数组分类查询获取商品列表
export function productListsAPI(data) {
  return request({
    url: `/Product/findListProductByArray?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// //全局搜索文章列表
// export function searchArticleListAPI(data) {
//   return request({
//     url: `/article/findGlobal?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
//     method: 'post',
//     data
//   });
// }

// 获取文章详情
export function productDetailAPI(params) {
  let newParam = {...params, ...info};
  return request({
    url: '/Product/findProductById',
    method: 'get',
    params: newParam
  });
}
// 新建文章
export function createProductAPI(data) {
  return request({
    url: `/Product/addProduct?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// 修改文章
export function updateProductAPI(data) {
  return request({
    url: `/Product/updateProduct?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// 删除文章
export function deleteProductAPI(data) {
  return request({
    url: `/Product/deleteProduct?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}