<template>
    <!-- <meta>111</meta> -->
    <div class="propertiesDetail">
        <!-- 海外房产详情 -->
        <div class="banner">
            <div class="WebMain">
                <p class="title p28">
                    {{ productDetail.title }}
                </p>
                <p class="btn p18" @click="openLine">
                    立即咨询
                </p>
            </div>
        </div>
        <div class="topMenu">
            <div class="WebMain">
                <ul>
                    <li @click="toLink(item)" :class="activeName === item.name ? 'active' : ''" v-for="item in TypeList"
                        :key="item.id">
                        <p class="p20">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="detail">
            <div class="WebMain">
                <div class="item" :id="0">
                    <div class="titleContent">
                        <p class="title"><span></span> 项目介绍</p>
                    </div>
                    <div class="content">
                        <div class="top">
                            <div class="left">
                                <el-carousel height="267px">
                                    <el-carousel-item v-for="item in productDetail.img" :key="item.id">
                                        <!-- <h3 class="small">{{ item }}</h3> -->
                                        <img :src="$store.state.requestAPI + item.src" alt="">
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                            <div class="right">
                                <p v-for="item in productDetail.configurations" :key="item.id"> <span class="label p16">{{
                                    item.name }}</span><span class="ele" v-for="ele in item.values">{{ ele }}</span></p>
                            </div>
                        </div>
                        <p class="summary p16">{{ productDetail.summary }}</p>
                    </div>
                </div>
                <div class="item" v-for="item in productDetail.configurationsNew" :id="item.id">
                    <div class="titleContent">
                        <p class="title"><span></span> {{ item.name }}</p>
                    </div>
                    <div class="content">
                        <p class="p16" v-for="ele in item.values" :key="ele">{{ ele }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { productDetailAPI } from '@/api/product/product'
export default {
    name: 'detail',
    // metaInfo: {
    //     title: this.metaTitle,
    //     meta: [
    //         // { charset: 'utf-8' },
    //         { name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' },
    //         // { name: 'referrer', content: 'never' }
    //     ]
    // },

    data() {
        return {
            productDetail: {
                title: '1111'
            },
            activeName: '项目介绍',
            TypeList: [{ id: 0, name: '项目介绍' }],
            metaTitle: '标题'

        }
    },
    // metaInfo: {
    //     title: this.myTitle,
    //     titleTemplate: '%s - by vue-meta',
    //     htmlAttrs: {
    //         lang: 'zh'
    //     },
    //     script: [{ innerHTML: 'console.log("hello hello!")', type: 'text/javascript' }],
    //     __dangerouslyDisableSanitizers: ['script']
    // },
    methods: {
        // 获取文章详情
        async getDetail() {
            const res = await productDetailAPI({ id: this.$route.params.id });
            console.log(res);
            if (res.code === 200) {
                this.productDetail = res.data;
                // console.log(this.productDetail.metaTitle)
                // console.log(this.productDetail.metaTitle)
                let metaInfo = {
                    title: this.productDetail.metaTitle,
                    keywords: this.productDetail.metaKeywords,
                    description: this.productDetail.metaDescription
                }
                this.$store.commit("CHANGE_META_INFO", metaInfo);
                this.TypeList = [...this.TypeList, ...res.data.configurationsNew]
            }
        },
        // 点击咨询
        openLine() {
            this.$store.commit('changeConnectShow');
            // console.log(this.show)
            // this.show = !this.show
        },
        // 锚点跳转
        toLink(val) {
            // console.log(val)
            this.activeName = val.name
            window.document.getElementById(val.id).scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
            // this.activeName = val.title
            // if (val.id === 1) {
            //     window.document.getElementById("introduce").scrollIntoView(false);
            // } else {
            //     window.document.getElementById("source").scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
            // }

        }
    },
    created() {
        // console.log(this.$route.params.id )
        this.getDetail();
        // console.log(this.productDetail.metaTitle)

    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.propertiesDetail {
    position: relative;
    padding-bottom: 40px;

    .banner {
        width: 100%;
        height: 166px;
        background-image: url('../../assets/img/detail/banner2.png');
        background-size: cover;
        background-repeat: no-repeat;
        // color: white;
        padding-top: 32px;
        box-sizing: border-box;

        .title {
            font-weight: 600;
            color: #202020;
            line-height: 40px;
        }

        .btn {
            width: 125px;
            height: 42px;
            background: var(--custom-color);
            border-radius: 4px;
            color: white;
            line-height: 42px;
            text-align: center;
            margin-top: 30px;
            cursor: pointer;
        }
    }

    .topMenu {
        width: 100%;
        min-height: 58px;
        background: #FFFFFF;
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.32);

        .WebMain {
            height: 100%;

            ul {
                height: 100%;
                padding: 0px;
                margin: 0px;

                li {
                    line-height: 56px;
                    // height: 100%;
                    list-style: none;
                    display: inline-block;
                    margin-right: 75px;
                    color: #202020;
                    cursor: pointer;
                }

                .active {
                    font-weight: 600;
                    color: var(--second-color);
                    border-bottom: 4px solid var(--second-color);
                }
            }
        }
    }

    .detail {

        // min-height: 800px;
        .item {
            background: white;
            margin-top: 20px;
            padding: 30px 50px;


            .titleContent {
                margin: 0;

            }

            .content {
                min-height: 300px;
                padding-top: 20px;

                p {
                    margin-top: 20px;
                    color: #202020;
                    line-height: 22px;
                }

                .top {
                    display: flex;
                    flex-wrap: wrap;


                    .left {
                        box-sizing: border-box;
                        width: 475px;
                        height: 267px;

                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .right {
                        width: 556px;
                        margin-left: 49px;

                        p {

                            min-height: 41px;
                            line-height: 41px;
                            border-bottom: 1px dotted #DDDDDD;
                            margin-top: 0px;

                            .label {
                                color: #202020;
                                margin-right: 27px;
                            }

                            .ele {
                                display: inline-block;
                                color: #777777;
                                font-size: 16px;
                            }

                            &:nth-child(2) {

                                span {
                                    &:nth-child(2) {
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: #ED1B1B;
                                    }
                                }
                            }
                        }
                    }
                }

                .summary {
                    margin-top: 32px;
                    color: #777777;
                    line-height: 24px;
                }

                ::v-deep p {
                    img {
                        max-width: 100% !important;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:950px) {
    .propertiesDetail .detail .item .content .top .right {
        margin-left: 0px;
    }

    .propertiesDetail .detail .item {
        padding: 30px 20px;
    }

    .propertiesDetail .topMenu .WebMain ul {
        display: flex;
        // justify-content: space-between;

        flex-wrap: wrap;

        li {
            margin: 0;
            margin-right: 10px;
        }
    }
}
</style>